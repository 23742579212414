(function($) {
    if($('#map_carte_interactive').length){
    	var base_url = window.location.origin;

    	 // Recupère un marker par son id
        L.Map.include({
            getMarkerById: function (id) {
                var marker = null;
                this.eachLayer(function (layer) {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id === id) {
                            marker = layer;
                        }
                    }
                });
                return marker;
            }
        });


    	// Definition de la map
        var map_carte_interactive = L.map('map_carte_interactive').setView([49.279144, -0.260871], 14);

        var OpenStreetMap_layer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        });

        OpenStreetMap_layer.addTo(map_carte_interactive);

        // Affichage des Maqueurs

        var markers = [];

        var marker_off = L.icon({
            iconUrl: base_url + '/wp-content/themes/createur/dist/images/marker_off.png',
            iconSize:     [28,42],
        });

        var marker_on = L.icon({
            iconUrl: base_url + '/wp-content/themes/createur/dist/images/marker_on.png',
            iconSize:     [28,42],
        });

        if( $('.list-items').length > 0){

        $('.list-items article').each(function(){

            var lat = $(this).data('lat');
            var lng = $(this).data('lng');
            var id = $(this).data('id');

            markers[id] = L.marker(
                [lat, lng],
                {
                    id: id,
                    icon: marker_off
                }
            ).addTo(map_carte_interactive);

            $(markers[id]).click(function (e) {
                setHover(id,true);
                scrollToAnnuaire(id,true);
            });

            });

    	    // Evenements
    	    $('.list-items article').click(function (e) {
    	        setHover($(this).data('id'),true);
                scrollToMap();
    	    });

    	    // Fonctions

    	    function setHover(id, pan){

    	        clearAll();

    	        // Set id
    	        var marker = map_carte_interactive.getMarkerById(id);
    	        marker.setIcon(marker_on);

    	        if(pan){
    	            map_carte_interactive.panTo(marker.getLatLng());
    	        }

    	        $('.mapContent article[data-id='+id+'] .annuaire-detail .categorie').css({
    	            'background-color' : '#0085cf'
    	        });

                $('.mapContent article[data-id='+id+'] .annuaire-detail').css({
                    'border-bottom' : '3px solid #0085cf'
                });
    	    }

            function scrollToAnnuaire(id, pan){

                // Set id
                var marker = map_carte_interactive.getMarkerById(id);
                marker.setIcon(marker_on);

                if(pan){
                    map_carte_interactive.panTo(marker.getLatLng());
                }

                var $container = $("html,body");
                var $scrollTo = $('.mapContent article[data-id='+id+']');
                $container.animate({scrollTop: $scrollTo.offset().top, scrollLeft: 0},500);
            }

            function scrollToMap(){

                var $container = $("html,body");
                var $scrollTo = $('#map_carte_interactive');
                $container.animate({scrollTop: $scrollTo.offset().top, scrollLeft: 0},500);

            }

    	    function clearAll() {
    	        for (var key in markers) {
    	            markers[key].setIcon(marker_off);
    	        }

                $('.mapContent article .annuaire-detail .categorie').css({
                    'background-color' : '#2f2f2f'
                });

    	        $('.mapContent article .annuaire-detail').css({
    	            'border-bottom' : 'none'
    	        });
    	    }

    	}
    }

})(jQuery);
